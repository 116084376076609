import React from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';
import Icon from 'core/components/Icon';
import LocationInfo from 'core/components/LocationInfo.js';
import {BP_FHIR, ZM, SHOW_REFERRAL_BTN} from 'core/constants.js';
import {getCopyData} from 'core/referrals.js';
import {TELEHEALTH_ONLY} from 'core/telehealth.js';
import {
    BPSecureMessageButton,
    BPWriteReferralButton,
    HSReferralButton,
    MDWriteReferralButton,
    ZMWriteReferralButton,
} from 'professional/components/ReferralButtons.js';
import {sessionStorageGetItem} from 'core/utils.js';

export default class ReferralModal extends React.Component {
    static propTypes = {
        client: PropTypes.string,
        closeModal: PropTypes.func.isRequired,
        displayName: PropTypes.string.isRequired,
        isReferrals: PropTypes.bool,
        locations: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                address: PropTypes.string,
                city: PropTypes.string,
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                postCode: PropTypes.string,
                practiceUrl: PropTypes.string,
            }).isRequired,
        ).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedLocation: this.props.locations[0],
        };
        this.selectRef = React.createRef();
    }

    componentDidMount() {
        this.setFocusOnSelect(this.selectRef.current);
    }

    @autobind
    setFocusOnSelect(element) {
        setTimeout(() => {
            element.focus();
        }, 0);
    }

    @autobind
    handleLocationChange(event) {
        const selectedLocation = this.props.locations.find(
            (location) =>
                parseInt(location.id) === parseInt(event.target.value),
        );
        this.setState({selectedLocation});
    }

    getLocationName(location) {
        const {localityState, name, postCode} = location;
        const city = location.city || name;
        const state = location.state || localityState;
        return `${city || ''} ${state || ''} ${postCode || ''}`.trim();
    }

    @autobind
    renderLocation(location) {
        if (location?.id) {
            return (
                <div className="location">
                    <LocationInfo
                        address={location.address}
                        practiceName={location.name}
                        practiceUrl={location.practiceUrl}
                        staticUrl="/static/"
                    />
                </div>
            );
        }

        return null;
    }
    renderReferralButtons(selectedLocation) {
        const {client, clientData, displayName, isReferrals} = this.props;
        let locData = clientData.find(
            (data) =>
                parseInt(data.locationId) === parseInt(selectedLocation.id),
        );
        if (!locData) {
            locData = clientData.find(
                (data) =>
                    parseInt(data.practicePositionId) ===
                    parseInt(selectedLocation.id),
            );
            if (!locData) {
                return null;
            }
        }
        const {bpData, mdData, referralEmail, zmData} = locData;
        const selectedId = locData.practicePositionId;
        const {offersTelehealth} = selectedLocation;
        const showReferralBtn =
            sessionStorageGetItem(SHOW_REFERRAL_BTN) === 'true';

        if (bpData || mdData || (zmData && showReferralBtn)) {
            return (
                <>
                    {bpData && (
                        <BPWriteReferralButton
                            bpData={bpData}
                            eventData={{
                                practicePositionId: selectedId,
                            }}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                    {mdData && (
                        <MDWriteReferralButton
                            eventData={{
                                practicePositionId: selectedId,
                            }}
                            mdData={mdData}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                    {bpData && client === BP_FHIR && (
                        <BPSecureMessageButton
                            eventData={{
                                practicePositionId: selectedId,
                            }}
                            fhirData={bpData}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                    {zmData && showReferralBtn && (
                        <ZMWriteReferralButton
                            eventData={{practicePositionId: selectedId}}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                            zmData={zmData}
                        />
                    )}
                </>
            );
        } else if ((zmData && !showReferralBtn) || isReferrals) {
            const getCopyDataWithProfile = () => {
                return getCopyData(displayName, {
                    ...selectedLocation,
                    referralEmail,
                });
            };
            return (
                <HSReferralButton
                    eventData={{
                        eventName: 'copyPracticeDetails',
                        data: {
                            'practice_position': selectedId,
                        },
                    }}
                    getCopyData={getCopyDataWithProfile}
                    key={selectedLocation.id}
                />
            );
        }
        return null;
    }

    renderButtonContent() {
        return <Icon name="close" />;
    }

    render() {
        const {closeModal, displayName, locations} = this.props;
        const {selectedLocation} = this.state;
        return (
            <>
                <Button
                    action={closeModal}
                    customClass="dismiss"
                    text={this.renderButtonContent()}
                />
                <h1>{`Referral to ${displayName}`}</h1>
                <form>
                    <select
                        onChange={this.handleLocationChange}
                        ref={this.selectRef}
                        value={selectedLocation?.id}
                    >
                        {locations.map((location, index) => {
                            return (
                                <option key={location.id} value={location.id}>
                                    {this.getLocationName(location)}
                                </option>
                            );
                        })}
                    </select>
                    {this.renderLocation(selectedLocation)}
                    <div className="button-group">
                        {this.renderReferralButtons(selectedLocation)}
                    </div>
                </form>
            </>
        );
    }
}
