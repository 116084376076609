import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import events from 'events.js';
import {getFocusableEls, trapTabKey} from 'utils/focus_helpers.js';

export default class Modal extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        closeModal: PropTypes.func.isRequired,
        initElement: PropTypes.object,
        parentElement: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.modalContentRef = React.createRef();
    }

    componentDidMount() {
        document.body.classList.add('modal-open');
        events.listen(this.ref.current, 'click', this.listenToClicks);
        events.listen(window, 'keydown', this.handleKeyDown);
        document.getElementById('modal').focus();
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
        events.unlisten(this.ref.current, 'click', this.listenToClicks);
        events.unlisten(window, 'keydown', this.handleKeyDown);
        this.bringBackFocus();
    }

    bringBackFocus() {
        // Bring back focus to the initial element and if that isn't
        // focusable bring it back to the element that has replaced it
        const {initElement, parentElement} = this.props;
        initElement.focus();
        if (initElement !== document.activeElement) {
            // eslint-disable-next-line
            parentElement.firstElementChild?.focus();
        }
    }

    @autobind
    listenToClicks(event) {
        if (!this.modalContentRef.current.contains(event.target)) {
            this.props.closeModal('outside click');
        }
    }

    @autobind
    handleKeyDown(event) {
        switch (event.key) {
            case 'Tab':
                if (!this.focusableEls?.includes(document.activeElement)) {
                    this.focusableEls = getFocusableEls('modal');
                }
                trapTabKey(this.focusableEls, event);
                break;
            case 'Escape':
            case 'Esc': // Edge specific value
                this.props.closeModal('esc key');
                break;
        }
    }

    render() {
        return (
            <div
                className="v2-modal"
                id="modal"
                ref={this.ref}
                role="dialog"
                tabIndex="-1"
            >
                <section className="modal-content" ref={this.modalContentRef}>
                    {this.props.children}
                </section>
            </div>
        );
    }
}
