import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import events from 'events.js';
import throttleDecorator from 'common/decorators/throttleDecorator.js';

export default class Tooltip extends React.Component {
    static propTypes = {
        // children is the element/s that will cause the tooltip to show up
        children: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.string,
            PropTypes.object,
        ]).isRequired,
        // tooltip content
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
        customClass: PropTypes.string,
        customTag: PropTypes.string,
        defaultPosition: PropTypes.string.isRequired,
        timeOut: PropTypes.number,
    };

    static defaultProps = {
        timeOut: 0,
    };

    constructor(props) {
        super(props);
        this.state = {
            isShown: true,
            position: '',
        };
        this.tooltipRef = React.createRef();
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        events.listen(document, 'click', this.hideTooltipOnClick);
        // required for the tooltip to have correct
        // position on initial render
        this.setPosition();
        this.setState({isShown: false});
    }

    componentWillUnmount() {
        events.unlisten(document, 'click', this.hideTooltipOnClick);
    }

    @autobind
    hideTooltipOnClick(event) {
        const {current} = this.containerRef;
        if (current && !current.contains(event.target)) {
            clearTimeout(this.timerID);
            this.hideTooltip();
        }
    }

    @throttleDecorator(200, {trailing: false})
    showTooltip(event) {
        if (
            this.state.isShown &&
            event.target.closest('div') !== this.tooltipRef.current
        ) {
            return this.setState({isShown: false});
        }
        this.setState({isShown: true}, () => {
            this.setPosition();
            this.setVertical();
        });
        clearTimeout(this.timerID);
    }

    @autobind
    hideTooltip() {
        this.setState({isShown: false});
    }

    @autobind
    delayHideTooltip() {
        this.timerID = setTimeout(this.hideTooltip, this.props.timeOut);
    }

    setPosition() {
        const {defaultPosition} = this.props;
        let newPosition = defaultPosition;
        const {position} = this.state;
        const container = this.containerRef.current;
        const {
            bottom,
            height,
            top,
        } = this.tooltipRef.current.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        const offset = container.getBoundingClientRect().height;
        const topSpace = top - height - offset;
        const bottomSpace = screenHeight - bottom - height - offset;

        if (
            defaultPosition === 'bottom' &&
            ((topSpace > 0 && bottom > screenHeight) ||
                (position === 'top' && bottomSpace <= 0))
        ) {
            newPosition = 'top';
        } else if (
            defaultPosition === 'top' &&
            ((top < 0 && bottomSpace > 0) ||
                (position === 'bottom' && topSpace <= 0))
        ) {
            newPosition = 'bottom';
        } else {
            newPosition = defaultPosition;
        }
        this.setState({position: newPosition});
    }

    @autobind
    setVertical() {
        const tooltip = this.tooltipRef.current;
        const {left, right} = tooltip.getBoundingClientRect();
        const screenWidth = document.body.getBoundingClientRect().width;
        const margin = 6;
        if (right > screenWidth) {
            // eslint-disable-next-line max-len
            tooltip.style.left = `calc(50% - ${
                right - screenWidth + margin
            }px)`;
        }
        if (left < 0) {
            tooltip.style.left = `calc(50% - ${left - margin}px)`;
        }
    }

    render() {
        const {position, isShown} = this.state;
        const {children, content, customClass, customTag} = this.props;
        const ElementName = customTag || 'div';
        return (
            <ElementName
                aria-expanded={content && isShown}
                className={`tooltip ${position} ${customClass || ''}`.trim()}
                onBlur={this.delayHideTooltip}
                onClick={this.showTooltip}
                onFocus={this.showTooltip}
                onMouseLeave={this.delayHideTooltip}
                onPointerOver={this.showTooltip}
                ref={this.containerRef}
                role="tooltip"
                tabIndex="0"
            >
                {children}
                {isShown && (
                    <div
                        aria-live="polite"
                        className="content"
                        onBlur={this.hideTooltip}
                        onFocus={this.showTooltip}
                        onMouseLeave={this.delayHideTooltip}
                        ref={this.tooltipRef}
                    >
                        {content}
                    </div>
                )}
            </ElementName>
        );
    }
}
