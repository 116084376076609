/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';
import LinkWithProperties from 'core/components/LinkWithProperties.js';
import query from 'query.js';
import {isIntegratedReferralsClient} from 'core/utils.js';

export default class LocationInfo extends React.Component {
    static propTypes = {
        address: PropTypes.string,
        client: PropTypes.string,
        practiceName: PropTypes.string.isRequired,
        practiceUrl: PropTypes.string,
        staticUrl: PropTypes.string,
        streetAddress: PropTypes.string,
        telehealthOnly: PropTypes.bool,
    };

    static defaultProps = {
        staticUrl: '/static/',
    };

    renderAddress() {
        const {address, client, streetAddress} = this.props;
        const googlePreviewUrl = `https://www.google.com/maps/preview/?q=${address}`;
        const displayAddress = streetAddress ? streetAddress : address;
        if (isIntegratedReferralsClient(client)) {
            return (
                <address>
                    <p>{displayAddress}</p>
                </address>
            );
        } else {
            return (
                <address>
                    <Link
                        href={googlePreviewUrl}
                        isExternalLink={true}
                        title="View on Google Map"
                    >
                        {displayAddress}
                    </Link>
                </address>
            );
        }
    }

    render() {
        const {
            address,
            practiceName,
            practiceUrl,
            staticUrl,
            telehealthOnly,
            hospitalGroup,
            isHospitalPage,
        } = this.props;
        const googleDirectionsUrl = `https://www.google.com/maps/dir/current+location/${address}`;
        const mapImgUrl = `${staticUrl}images/placeholder-map.jpg`;
        const selectedHF = query.parse().health_fund;

        return (
            <>
                {!telehealthOnly && (
                    <Link
                        customClass="directions"
                        href={googleDirectionsUrl}
                        isExternalLink={true}
                        title="Get Directions"
                    >
                        <img alt="" src={mapImgUrl} />
                        <Icon name="directions" />
                    </Link>
                )}
                <h3>
                    {practiceUrl ? (
                        <LinkWithProperties
                            href={practiceUrl}
                            searchParams={
                                selectedHF
                                    ? {'health_fund': selectedHF}
                                    : undefined
                            }
                        >
                            {practiceName}
                        </LinkWithProperties>
                    ) : (
                        practiceName
                    )}
                </h3>
                {hospitalGroup && (
                    <h4 className="hospital-group">{hospitalGroup.name}</h4>
                )}
                {!telehealthOnly && !isHospitalPage && this.renderAddress()}
            </>
        );
    }
}
